body,
html {
  background: #f9f9f9;
}

/* linear-gradient(rgba(174,65,47,1) 0%, rgba(232,171,111,1) 20%, rgba(109,163,152,1) 40%, rgba(195,90,201,1) 60%, rgba(118,168,154,1) 80%, rgba(203,101,52,1) 100%); */
button {
  border-radius: 100rem;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 3rem;
  color: #394150;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px #394150;
  background-origin: border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  transition: all 0.25s;
}

button:hover {
  box-shadow: none;
  color: white;
  background-color: #394150;
}

button.small-button {
  font-size: 0.9rem;
}

.text-header {
  -webkit-text-stroke: 1px #7792ff;
}
