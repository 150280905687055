@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: funkies;
  src: url(Funkies.ttf);
}

body {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.fun-font {
  font-family: funkies;
}

.gothic {
  font-family: "Tilt Prism", sans-serif;
}

.warlock {
  font-family: "MedievalSharp", cursive;
}

.divider {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(174, 65, 47, 1) 0%,
    rgba(232, 171, 111, 1) 20%,
    rgba(109, 163, 152, 1) 40%,
    rgba(195, 90, 201, 1) 60%,
    rgba(118, 168, 154, 1) 80%,
    rgba(203, 101, 52, 1) 100%
  );
}

.Social-links svg {
  transition: all 0.25s;
}

.Social-links svg {
  /* background-image: linear-gradient(90deg, rgba(174,65,47,1) 0%, rgba(232,171,111,1) 20%, rgba(109,163,152,1) 40%, rgba(195,90,201,1) 60%, rgba(118,168,154,1) 80%, rgba(203,101,52,1) 100%); */
  /* color: red; */
  /* background-clip: text; */
}

.Social-links:hover {
  cursor: pointer;
}

.Social-links .spotify svg:hover {
  color: rgba(174, 65, 47, 1);
}

.Social-links .bandcamp svg:hover {
  color: rgba(232, 171, 111, 1);
}

.Social-links .facebook svg:hover {
  color: rgba(109, 163, 152, 1);
}

.Social-links .instagram svg:hover {
  color: rgba(195, 90, 201, 1);
}
